:root {
  /*--brand-color: #5083b7;*/
  --brand-color: #002e5d;
}

.App {
  max-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.unknown {
  background-color: #f4cccc;
  border-radius: 5px;
  padding: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  border: 1px solid #f4cccc;
}

.unknown:hover {
  border: 1px solid #e06666;
}

.punctuation {
  padding: 6px 0px;
  margin-bottom: 2px;
}

.l {
  background-color: #d9ead3;
  border-radius: 5px;
  padding: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  border: 1px solid #d9ead3;
}

.l:hover {
  border: 1px solid #93c47d;
}

.i {
  border-radius: 5px;
  padding: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  border: 1px solid transparent;
}

.highlighted {
  background-color: #999999;
}

.not-highlighted + .highlighted {
  border-radius: 5px 0px 0px 5px;
}

.progress {
  background-color: #e06666;
}

.progress-bar {
  background-color: #93c47d;
}

.section-delete-button {
  float: right;
  display: block;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.full-width {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.text-center {
  text-align: center;
}

.language-icon {
  height: 20px;
  width: 20px;
  float: right;
}

.language-icon-lg {
  height: 35px;
  width: 35px;
}

.language-select {
  border: white solid 1px;
  border-radius: 5px;
  margin-right: 10px;
}

.language-select-lg {
  border: #6c757d solid 1px;
  border-radius: 5px;
  margin-right: 10px;
  height: 50px;
}

.language-select-lg a {
  height: 100%;
}

.table-header {
  background-color: var(--brand-color) !important;
  color: #fff;
  border-radius: 5px;
}

.center-text {
  text-align: center;
}

.Section__container___3YYTG {
  display: flex;
  flex-flow: column;
  height: 100%;
}

@media (min-width: 1200px) {
  body {
    height: 100vh;
  }

  .main-content-row {
    max-height: 100%;
    overflow-y: auto;
  }
}

.section-window {
  text-align: center;
  padding: 1% 1%;
}

.sidebar-window {
  padding: 1% 1% 2% 1%;
  height: 100%;
  max-height: 100%;
}

@media (max-width: 992px) {
  .dropdown {
    margin-bottom: 0.25rem !important;
  }

  .navbar-nav .nav-link {
    padding: 0.25rem 1.5rem;
  }

  #basic-navbar-nav {
    margin-top: 0.25rem !important;
  }
}

@media (max-width: 1200px) {
  .instructions-text {
    font-size: 0.8em;
    min-height: 70vh;
  }
  .language-icon-lg {
    height: 30px;
    width: 30px;
  }
  .image-background {
    min-height: 100vh;
    background-size: cover;
  }
}

@media (max-width: 576px) {
  .instructions-text {
    font-size: 0.8em;
    min-height: 100vh;
  }
}

@media (min-width: 1200px) {
  .image-background {
    background-size: 100%;
  }
}

.image-background {
  background-image: url(./images/laptop.jpg);
  background-repeat: no-repeat;
  height: 100%;
}

.instructions-text {
  color: white;
}

.sidebar {
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
}

.dropdown-menu {
  width: 100%;
}

.no-sections-instructions {
  color: #cccccc;
  font-size: 3em;
}

.nav-tabs .nav-link {
  width: 50%;
  text-align: center;
  background-color: white;
}

.nav-tabs .nav-link.active {
  background-color: #e2e3e5;
  border-bottom: none;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.tab-content {
  background-color: #e2e3e5;
}

.alert {
  border-radius: 1rem;
}

.bg-dark {
  background-color: var(--brand-color) !important;
}

/*
.gradient-background {
  background: rgb(80, 131, 183);
  background: linear-gradient(
    180deg,
    rgba(80, 131, 183, 1) 0%,
    rgba(154, 184, 214, 1) 100%
  );
} */

.full-width-btn {
  width: 100%;
}

.form-title {
  text-align: center;
}

.form-alert {
  margin: 5px;
}

.section-window > div > div {
  /* margin-bottom: 1rem; */
  height: auto;
  /* display: contents; */
}

.word {
  cursor: pointer;
}

.float-right {
  float: right;
}

.no-padding-right {
  padding-right: 0px;
  padding-bottom: 3px;
}

.light-grey-border-bottom {
  border-bottom: 1px solid #efefef;
}

.placeholder {
  width: 100%;
  height: 100%;
  min-height: 400px;
  text-align: center;
  font-size: 3em;
}

.tab-content {
  height: 100%;
}

.navbar-brand {
  transition: transform 0.2s;
  cursor: default;
}

.navbar-brand:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 0.3rem 0.2rem rgba(10, 10, 10, 0.9));
}

.section-words {
  line-height: 1.8;
  user-select: none;
}

.dropdown-item > img {
  transition: transform 0.2s;
}

.dropdown-item:hover > img {
  transform: scale(1.2);
}

*:focus {
  outline: none;
}

.language-icon-signin {
  height: 30px;
  width: 30px;
  float: right;
}

.language-icon-lg {
  height: 50px;
  width: 50px;
  float: right;
}

/*
.shadow {
  filter: drop-shadow(0 0.2rem 0.2rem rgba(39, 39, 39, 0.9));
} */

.page-item {
  width: 7.692%;
}

.tutorial-image {
  max-width: inherit;
  max-height: 100%;
  display: block;
  /* margin-left: auto;
  margin-right: auto; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.tutorial-image-window {
  height: 50vh;
  user-select: none;
}

.tutorial-text-window {
  height: 10vh;
  text-align: center;
  user-select: none;
}

.double-font-size {
  font-size: 2em;
}

.bold-text {
  font-weight: bold;
}

#little {
  fill: pink;
}
