.facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff;
    text-shadow: none;
    background-image: none;
    -webkit-box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

.facebook:hover {
    background-color: #304d8a;
    border-color: #304d8a;
    color: #fff;
    text-shadow: none;
}

.facebook:active, .facebook:focus {
    background-color: #2d4373!important;
    border-color: #2d4373!important;
    color: #fff;
    text-shadow: none;
}

.google {
    background-color: #dd4b39;
    border-color: #dd4b39;
    color: #fff;
    text-shadow: none;
    background-image: none;
    -webkit-box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

.google:hover {
    background-color: #e0321c;
    border-color: #e0321c;
    color: #fff;
    text-shadow: none;
}

.google:active, .google:focus {
    background-color: #c23321!important;
    border-color: #c23321!important;
    color: #fff;
    text-shadow: none;
}