body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /*overflow: hidden;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100%;
  min-height: 100vh;
}

.Section__container___3YYTG{
  min-height: 100vh;
}

.window-fill {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.page-footer {
  flex: 0 1 10px;
}
